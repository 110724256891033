import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Chart } from "primereact/chart";

/**
 * This component is used to display the current jobs graph.
 * The graph shows the number of ongoing, on hold, completed, and pending jobs.
 */
const CurrentJobsGraph = () => {
  /**
   * State to hold the chart data.
   * This state is updated whenever the chart data is fetched.
   */
  const [chartData, setChartData] = useState([]);

   const userData = JSON.parse(localStorage.getItem("userData")) || "{}";


 const [selectedPlant, setSelectedPlant] = useState(() => {
   if (
     userData &&
     userData.company_and_plants &&
     userData.company_and_plants.length > 0
   ) {
     return userData.company_and_plants[0].plant_code;
   }
   return "";
 });

  /**
   * Fetches chart data from the server.
   * This function is called when the component is mounted.
   */


    const fetchChartData = async () => {
    try {
      const response = await axiosPrivate.get(`dashboard/all_jobs?plant_code=${selectedPlant}`);
      if (response.status === 200) {
        setChartData(response?.data?.data);
        // console.log(response.data?.data)
        // let total_sum = 0;
        // response?.data?.data?.forEach(item => {total_sum += Number(item)});
        // console.log(total_sum);
        // setTotalJobs(total_sum);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  /**
   * Calls the fetchChartData function when the component is mounted.
   */
  useEffect(() => {
    fetchChartData();
  }, [selectedPlant]);

  /**
   * State to hold the jobs chart data.
   * This state is updated whenever the chart data is fetched.
   */
  const [jobsChartData, setJobsChartData] = useState({});

  /**
   * State to hold the jobs chart options.
   * This state is updated whenever the chart data is fetched.
   */
  const [jobsChartOptions, setJobsChartOptions] = useState({});

  /**
   * Updates the jobs chart data and options when the chart data is updated.
   */
  useEffect(() => {
    const jobsData = {
      labels: [
        "Ongoing Jobs",
        "Jobs On Hold",
        "Jobs Completed",
        "Pending Jobs",
      ],
      datasets: [
        {
          label: "Jobs",
          data: chartData,
          backgroundColor: [
            "rgb(2, 136, 209)",
            "rgb(211, 47, 47)",
            "rgb(104, 159, 56)",
            "rgb(251, 192, 45)",
          ],
        },
      ],
    };

    const jobsOptionsData = {
      legend: {
        position: "bottom",
      },
    };

    setJobsChartData(jobsData);
    setJobsChartOptions(jobsOptionsData);
  }, [chartData]);

 let total_jobs = 0;
  chartData?.forEach((item) => {
   total_jobs += Number(item);
  });
 

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title'>
          <strong>Production</strong>
        </h3>
      </div>
      <div className='card-body '>
        {Number(userData?.role_id) === 3 ? (
          <div className='col-xl-12 col-lg-12 col-md-12'>
            <div className='dropdown text-left w-100'>
              <select
                className='btn dropdown-toggle'
                style={{ background: "#ebebeb" }}
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                {userData?.company_and_plants?.map((plant) => (
                  <option
                    value={plant?.plant_code}
                    key={plant?.plant_id}
                  >
                    {plant?.plant_name}
                  </option>
                ))}
              </select>
              {/* Calendar inputs for custom range */}
            </div>
          </div>
        ) : null}

        {total_jobs > 0 ? (
          <Chart
            type='pie'
            data={jobsChartData}
            className='w-full md:w-30rem'
            options={jobsChartOptions}
          />
        ) : (
          <div className='d-flex justify-content-center align-items-center h-100'>
            No Current Jobs
          </div>
        )}
      </div>
    </div>
  );
};



export default CurrentJobsGraph;
