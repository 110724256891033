import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import Logo from "../../assets/images/logo.png";
import logo1 from "../../assets/Logos/2000.png";
import logo2 from "../../assets/Logos/3000.png";
import logo3 from "../../assets/Logos/4000.png";
import logo4 from "../../assets/Logos/5000.png";
import { axiosPublic } from "../../hooks/axiosPublic";

const Login = () => {
  const navigate = useNavigate();

  // Form validation schema
  const loginSchema = Yup.object({
    // email: Yup.string().email().required("Email is Required"),
    emp_code: Yup.string().required("Employee Code is Required"),
    password: Yup.string().required("Password is Required"),
  });

  // Initial form values
  const initialValues = {
    // email: "",
    emp_code: "",
    password: "",
  };


  /**
   * Handles the form submission.
   *
   * @param {Object} values - The values submitted in the form.
   * @returns {Promise} A promise that resolves when the submission is complete.
   */
  const handleSubmit = async (values) => {
    let response;

    try {
      // Send a POST request to the login endpoint with the form values.
      response = await axiosPublic.post("login", values);

      // If the response status is 200, the login was successful.
      if (response.status === 200) {
        // Save the user data to local storage.
        localStorage.setItem("userData", JSON.stringify(response.data));

        // Show a success toast message.
        showSuccessToast("Login Successful!");

        // Redirect to the home page.
        navigate("/");
      }
    } catch (error) {
      // Show an error toast message with the error message.
      showErrorToast(error.response?.data?.errors[0].msg || 'Something went wrong');
    }
  };


  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {/* Left side of the login page */}

      <div className='auth_left'>
        <div className='card'>
          <div className='text-center mb-2'>
            {/* Logo */}
            <Link
              className='header-brand'
              to='/'
            >
              <img
                style={{
                  height: "30px",
                  width: "150px",
                }}
                src={Logo}
                alt='logo'
              />
            </Link>
          </div>

          <div className='card-body'>
            <form onSubmit={formik.handleSubmit}>
              {/* Login form */}
              <div className='card-title'>Login to your account</div>
              
              {/* Employee Code */}
              <div className='form-group'>
                <label className='form-label'>Employee Code</label>
                <input
                  type='number'
                  name='emp_code'
                  className='form-control'
                  value={formik.values.emp_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Employee Code'
                />
                {formik.touched.emp_code && formik.errors.emp_code && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.emp_code}
                  </div>
                )}
              </div>
              {/* Password input */}
              <div className='form-group'>
                <label className='form-label'>
                  Password
                  <Link
                    className='float-right small'
                    style={{ color: "var(--gray)" }}
                    to='/request-password'
                  >
                    Request password
                  </Link>
                </label>
                <input
                  type='password'
                  name='password'
                  className='form-control'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Password'
                />
                {formik.touched.password && formik.errors.password && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.password}
                  </div>
                )}
              </div>
              {/* Submit button */}
              <div className='form-footer'>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Login'
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Right side of the login page with carousel */}
      <div className='auth_right'>
        <svg className="bglogo" width="595" height="398" viewBox="0 0 595 398" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M210.714 0H417.916L207.202 193.447H0L210.714 0Z" fill="#EEEEEE" />
          <path d="M239.395 202.229L428.745 18.1465V223.885L239.395 397.139V202.229Z" fill="#EEEEEE" />
          <path d="M671.36 0H464.157L674.871 193.447H882.074L671.36 0Z" fill="#EEEEEE" />
          <path d="M642.678 202.229L453.328 18.1465V223.885L642.678 397.139V202.229Z" fill="#EEEEEE" />
        </svg>
        <div className="row logoRow">
          <div className="col">
            <div className="card logoCard ">
              <div className="image_card_logo">
                <img
                  className="image_card_logo"
                  src={logo1}
                  alt='logo'
                />
              </div>

            </div>
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo2}
                alt='logo'
              />
            </div>
          </div>
          <div className="col">
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo3}
                alt='logo'
              />
            </div>
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo4}
                alt='logo'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
