import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";

const DispatchGraph = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [jobsMonthChartData, setJobsMonthChartData] = useState({});
  const [JobsMonthChartOptions, setJobsMonthChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);



  /**
   * Fetches and processes the monthly dispatch data for the dashboard chart.
   *
   * This function is responsible for making a request to the server to retrieve the monthly dispatch data.
   * It checks if the `fromDate` and `toDate` state variables are set, and if so, it includes them in the request.
   * If the `fromDate` and `toDate` are not set, it makes the request without any date parameters.
   * The response data is then stored in the `chartData` state variable.
   * If an error occurs during the request, it displays an error toast message.
   */
  const fetchChartData = async () => {
    try {
      let response;
      const options = {
        month: "2-digit",
        year: "2-digit",
      };
      if (fromDate && toDate) {
        response = await axiosPrivate.post("dashboard/monthly_dispatch", {
          fromDate: new Date(fromDate).toLocaleDateString("en-GB", options),
          toDate: new Date(toDate).toLocaleDateString("en-GB", options),
        });
      } else {
        response = await axiosPrivate.post("dashboard/monthly_dispatch", {});
      }
      if (response.status === 200) {
        const data = response?.data?.data;
        setChartData(data);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    if ((fromDate && toDate) || selectedRange !== "Custom") {
      fetchChartData();
    }
  }, [selectedRange, fromDate, toDate]);

  /**
   * Fetches and processes chart data for the monthly dispatch graph.
   *
   * This effect is triggered when the `chartData`, `selectedRange`, `fromDate`, or `toDate` state changes.
   * It fetches the monthly dispatch data from the server and transforms it into a format suitable for the chart component.
   * The transformed data is then stored in the `jobsMonthChartData` and `jobsMonthChartOptions` state variables.
   */
  useEffect(() => {
    const labels = Array.from(
      new Set(
        chartData?.map(
          (item) => `${item?.year}-${item?.month?.toString()?.padStart(2, "0")}`
        )
      )
    ).sort();
    const dataArr = [];
    chartData?.reduce((acc, { year, month, sum, Plant }) => {
      const date = `${year}-${month?.toString()?.padStart(2, "0")}`;
      if (!acc[Plant]) {
        acc[Plant] = {
          label: `Plant ${Plant}`,
          data: Array(labels?.length).fill(0), // initialize with null for months with no data
          borderColor: `#${Math.floor(Math.random() * 16777215)?.toString(16)}`, // random color
          borderWidth: 1.5,
          tension: 0.5,
        };
        dataArr.push(acc[Plant]);
      }
      const index = labels.indexOf(date);
      acc[Plant].data[index] = parseFloat(sum);
      return acc;
    }, {});

    const jobMonthData = {
      labels: labels,
      datasets: dataArr,

      borderWidth: 1.5,
      tension: 0.5,
    };

    const jobsMonthOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setJobsMonthChartData(jobMonthData);
    setJobsMonthChartOptions(jobsMonthOptions);
  }, [chartData]);

  const handleRangeChange = (e) => {
    setSelectedRange(e.target.value);

    setFromDate(null);
    setToDate(null);
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title justify-content-center'>
          <strong>Monthly Dispatch</strong>
        </h3>
      </div>
      <div className='card-body'>
        <div className='col-xl-12 col-lg-12 col-md-12'>
          <div className='dropdown  w-100'>
            <select
              className='btn dropdown-toggle'
              style={{ background: "#ebebeb" }}
              onChange={handleRangeChange}
              value={selectedRange}
            >
              <option
                value='6'
               
              >
                Last 6 Months
              </option>
              <option
                value='Custom'
               
              >
                Custom
              </option>
            </select>
            {/* Calendar inputs for custom range */}
            {selectedRange === "Custom" && (
              <div className='d-flex flex-row justify-content-between align-items-end w-100 mt-2'>
                <div>
                  <label className='form-label text-left'>From:</label>
                  <Calendar
                    value={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                    view='month'
                    dateFormat='mm/yy'
                    showIcon
                  />
                </div>
                <div>
                  <label className='form-label'>To:</label>
                  <Calendar
                    value={toDate}
                    onChange={(e) => setToDate(e.value)}
                    showIcon
                    view='month'
                    dateFormat='mm/yy'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Chart
          type='line'
          data={jobsMonthChartData}
          options={JobsMonthChartOptions}
        />
      </div>
    </div>
  );
};

export default DispatchGraph;
