import React, { useEffect, useState } from "react";
import TopNavBar from "../../Layouts/TopNavBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dropdown } from "primereact/dropdown";


const productCategorySchema = Yup.object({
  product_category_name: Yup.string().required("Product Group Name is Required"),
  // product_category_description: Yup.string().required("Description is Required"),
  plant_id: Yup.string().required("Plant is Required"),
  // user_id: Yup.string().required("Supervisor is Required")
});
const productCategoryForm = {
  product_category_name: "",
  product_category_description: "",
  plant_id: "",
  user_id:"",

};

const AddEditProductCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [plantsData, setplantsData] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const { userId } = JSON.parse(localStorage.getItem("userData"));

  // Fetch Plant data if id is provided and user wants to update
  useEffect(() => {
    const getProductCategory = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`product_category/${id}`);
          setValues(res?.data?.data.user_id ? {...res?.data?.data , user_id : res?.data?.data.user_id.toLocaleString()} : res?.data?.data);
          
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getProductCategory();
    }
  }, [id]);

  // Function to fetch company data for dropdown
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("plant");
      if (response.status === 200) {
        setplantsData(response?.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  const fetchSupervisorsData = async () => {
    try {
      const response = await axiosPrivate.get(`users/supervisors/${userId}`);
      if (response.status === 200) {
        setSupervisors(response.data?.data);
     
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: productCategoryForm,
    validationSchema: productCategorySchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          await axiosPrivate.put(`product_category/${id}`, values);
          navigate("/productcategory");
          showSuccessToast("Product Category updated successfully");
        } else {
          await axiosPrivate.post(`product_category`, values);
          navigate("/productcategory");
          showSuccessToast("Product Category added successfully");
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });
  return (
    <div className='content'>
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100vh - 106px)` }}
      >
        <div
          className='card  '
          style={{ width: "50%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className='card-header'>
              <strong>Add Product Category</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Select Plant</label>
                    <select
                      className='form-control show-tick'
                      name='plant_id'
                      id='plant_id'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.plant_id}
                    >
                      <option value=''>--Select Plant--</option>
                      {plantsData.map((plant, index) => (
                        <option
                          key={index}
                          value={plant.id}
                        >
                          {plant.name}
                        </option>
                      ))}
                    </select>
                    {touched.plant_id && errors.plant_id && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.plant_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Product Category Name</label>
                    <input
                      id='product_category_name'
                      name='product_category_name'
                      type='text'
                      className='form-control'
                      placeholder='Product-Category Name *'
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue(
                          "product_category_name",
                          values.product_category_name.trim().toUpperCase()
                        );
                      }}
                      value={values.product_category_name}
                    />
                    {touched.product_category_name &&
                    errors.product_category_name ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.product_category_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='col-md-12 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>
                      Select Default Supervisor
                    </label>
                    <Dropdown
                      name='user_id'
                      options={supervisors}
                      value={values.user_id}
                      onChange={(e) => setFieldValue("user_id", e.value)}
                      placeholder='Select Supervisor'
                      optionLabel='label'
                      optionValue='value'
                      filter
                      showClear={values?.user_id?.length}
                      filterBy='label'
                      className='w-100'
                    />
                    {touched.user_id && errors?.user_id && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.user_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-12 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Description</label>
                    <textarea
                      name='product_category_description'
                      id='product_category_description'
                      placeholder='Product-Category Description '
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.product_category_description}
                    ></textarea>
                  </div>
                  {touched.product_category_description &&
                    errors.product_category_description && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.product_category_description}
                      </div>
                    )}
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />

                  <button
                    type='button'
                    id='button_1'
                    className='btn btn-secondary mx-1 '
                    data-dismiss='modal'
                    onClick={() => navigate("/productcategory")}
                  >
                    CLOSE
                  </button>
                  <button
                    type='submit'
                    id='button_2'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditProductCategory;
