import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../hooks/axiosPrivate";
import { showErrorToast } from "../utils/Toaster";

import company1 from "../assets/Logos/2000.png";
import company2 from "../assets/Logos/3000.png";
import company3 from "../assets/Logos/4000.png";
import company4 from "../assets/Logos/5000.png";
import { axiosPublic } from "../hooks/axiosPublic";

const Header = () => {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage?.getItem("userData")) || {};

  const handleLogout = async () => {
    try {
      if (userData) {
        await axiosPublic.post("logout", { user_id: userData?.userId });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const logoutHandler = () => {
    // Remove user data from local storage
    handleLogout();
    localStorage.removeItem("userData");
    localStorage.removeItem("userRefreshToken");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("currentPlant");

    // Navigate to the login page
    navigate("/login");
  };

  // State for storing notifications
  const [notificationsData, setNotificationsData] = useState([]);

  // Function to fetch notifications data
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("notifications");
      if (response?.status === 200) {
        setNotificationsData(response.data?.data);
      }
    } catch (error) {
      // Handle errors appropriately (e.g., show error toast)
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.userId || !userData.userName) {
      navigate("/login");
    }
  }, [navigate, userData]);

  // Fetch notifications data on component mount
  // useEffect(() => {
  //   fetchData();  
  // }, []);

  return (
    <div
      id='page_top'
      className='container-fluid'
      style={{
        padding: "0px 12px",
        position: "sticky",
        top: "0px",
        zIndex: "100",
        background: "#ffffff",
      }}
    >
      <div className='page-header'>
        {/* Left section */}
        <div className='left'>
          <div className='input-group xs-hide '>
            <Link
              to='/'
              style={{ display: "flex", alignItems: "center" }}
            >
              {userData?.company_code === "2000" ? (
                <img
                  style={{
                    height: "25px",
                    width: "175px",
                    marginRight: "10px", // Add margin to create space between logo and text
                  }}
                  src={company1}
                  alt='logo'
                />
              ) : userData?.company_code === "3000" ? (
                <img
                  style={{
                    height: "35px",
                    width: "200px",
                    marginRight: "10px", // Add margin to create space between logo and text
                  }}
                  src={company2}
                  alt='logo'
                />
              ) : userData?.company_code === "4000" ? (
                <img
                  style={{
                    height: "35px",
                    width: "200px",
                    marginRight: "10px", // Add margin to create space between logo and text
                  }}
                  src={company3}
                  alt='logo'
                />
              ) : userData?.company_code === "5000" ? (
                <img
                  style={{
                    height: "35px",
                    width: "200px",
                    marginRight: "10px", // Add margin to create space between logo and text
                  }}
                  src={company4}
                  alt='logo'
                />
              ) : (
                <img
                  style={{
                    height: "35px",
                    width: "200px",
                    marginRight: "10px", // Add margin to create space between logo and text
                  }}
                  src={company1}
                  alt='logo'
                />
              )}
            </Link>
          </div>
        </div>
        {/* Right section */}
        <div className='right'>
          <div className=' d-flex justify-content-center align-items-center'>
            {/* Notification dropdown */}
            <div className='dropdown d-flex'>
              <a
                href='/#'
                className='nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1'
                data-toggle='dropdown'
                onClick={() => fetchData()}
              >
                <i className='fa fa-bell'></i>
                {notificationsData && notificationsData?.length ? (
                  <span className='dot'></span>
                ) : null}
              </a>
              <div
                className=' notification dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                style={{
                  height: "400px",
                  overflowY: "scroll",
                  margin: "0px",
                  padding: "0px",
                  minWidth: '350px'
                }}
              >
                <div
                  className='d-flex dropdown-header align-items-center mt-0'
                  style={{
                    position: "sticky",
                    top: "-1px",
                    zIndex: "100",
                    background: "#ffffff",
                    
                  }}
                >
                  <h5 className='mb-0'>Notifications</h5>
                  <span className=' notification_number p-2 ml-auto'>
                    {notificationsData?.length} New
                  </span>
                </div>
                {notificationsData && notificationsData?.length > 0 ? (
                  // Render notifications if available
                  <ul className=' border-top list-unstyled feeds_widget'>
                    {notificationsData.map((notification) => (
                      <li
                        key={notification.id}
                        className='p-2'
                        style={{ alignItems: "baseline" }}
                      >
                        <Link
                          // to={`/${notification.model}/edit/${notification.id}`}
                          style={{ cursor: "pointer" }}
                          onClick={async (event) => {
                            event.preventDefault(); // Prevent default link behavior
                            try {
                              // Update notification status and navigate to appropriate page
                              await axiosPrivate.put(
                                `notifications/${notification.id}`
                              );
                              fetchData();
                              // navigate(
                              //   `/${notification.model}/edit/${notification.id}`
                              // );
                            } catch (error) {
                              // Handle errors appropriately (e.g., show error toast)
                              if (error.code !== 404) {
                                showErrorToast(error.message);
                              }
                            }
                          }}
                        >
                          {/* Render notification content inside the Link component */}
                          {/* Adjust this part according to your notification structure */}

                          <div className='feeds-body d-flex justify-content-between flex-column align-items-start '>
                            <h6 className='notification_title'>
                              {notification.title}
                              {/* <small className='float-right text-muted'>
                                    {new Date(
                                      notification.created_at
                                    ).toLocaleDateString("en-GB")}
                                  </small> */}
                            </h6>
                            <p
                              className='text-muted m-0 p-0'
                              style={{ fontSize: "12px" }}
                            >
                              {notification.description}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  // Render message if no notifications available
                  <div className='text-center'>No New Notifications!</div>
                )}
              </div>
            </div>

            {/* User profile dropdown */}
            <div className='dropdown d-flex'>
              <a
                href='/#'
                className='nav-link icon d-none d-flex btn btn-default btn-icon ml-1 justify-content-center align-items-center'
                data-toggle='dropdown'
              >
                <i className='fa fa-user'></i>
                <span
                  style={{
                    marginTop: "1px",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  {userData.userName}
                </span>
              </a>
              <div className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'>
                <a
                  className='dropdown-item'
                  href='/profile'
                >
                  <i className='dropdown-icon fe fe-user'></i> Profile
                </a>
                <a
                  className='dropdown-item'
                  onClick={logoutHandler}
                  href='# '
                >
                  <i className='dropdown-icon fe fe-log-out'></i> Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
