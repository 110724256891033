import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(null);

   const userData = JSON.parse(localStorage.getItem("userData")) || {};
  
  const navigate = useNavigate(); // Use navigate hook

  // Fetch user data
  useEffect(() => {
    const getUser = async () => {
      try {       
        if (!userData || !userData.userId || !userData.userName) {
          navigate("/login");
        }
        if (userData) {
          setUser(userData);
          updateUserFormik.setValues({ ...userData, name: userData?.userName });
          updatePasswordFormik.setFieldValue("emp_code", userData?.emp_code);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    getUser();
  }, []);

  // Validation schema for updating user information
  const userSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number")
      .required("Mobile number is required"),
  });

  // Validation schema for updating password
  const passwordSchema = Yup.object({
    emp_code: Yup.string()
      .required("Employee Code is required"),
  });

  // Formik hook for updating user information
  const updateUserFormik = useFormik({
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      mobile: user?.mobile || "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      
      try {
        const { name, email, mobile, emp_code } = values; // Destructure only the required fields
        const response = await axiosPrivate.put(`users/${user.userId}`, { name, email, mobile, emp_code }); // Send only the required fields in the payload

        const prevData = JSON.parse(localStorage.getItem("userData"));

        const newData = {
          ...prevData,
          userName: response?.data?.data[0]?.name,
          email: response?.data?.data[0]?.email,
          mobile: response?.data?.data[0]?.mobile,
        };
        // Remove user data from localStorage
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(newData));
        // Navigate user to login page
       
        showSuccessToast("User information updated successfully");
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });

  // Formik hook for updating password
  const updatePasswordFormik = useFormik({
    initialValues: {
      emp_code: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      try {
        await axiosPrivate.post(`request-password`, values);
        showSuccessToast("Request Sent successfully");
      } catch (error) {
        showErrorToast(error.response?.data?.errors[0]?.msg || 'Something went wrong. Please try again later');
      }
    },
  });

  // JSX for rendering user information update form
  const renderUserInfoForm = () => (
    <form onSubmit={updateUserFormik.handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='name'>Name *</label>
            <input
              id='name'
              type='text'
              name='name'
              className='form-control'
              placeholder='Enter your name'
              value={updateUserFormik.values.name}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.name && updateUserFormik.errors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {updateUserFormik.errors.name}
              </div>
            )}
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='emp_code'>Employee Code *</label>
            <input
              id='emp_code'
              type='emp_code'
              name='emp_code'
              className='form-control'
              readOnly
              value={updateUserFormik.values.emp_code}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
          </div>
        </div>

        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='email'>Email *</label>
            <input
              id='email'
              type='email'
              name='email'
              className='form-control'
              placeholder='Enter your email'
              value={updateUserFormik.values.email}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.email &&
              updateUserFormik.errors.email && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.email}
                </div>
              )}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='mobile'>Mobile *</label>
            <input
              id='mobile'
              type='text'
              name='mobile'
              className='form-control'
              placeholder='Enter your mobile number'
              value={updateUserFormik.values.mobile}
              onChange={updateUserFormik.handleChange}
              onBlur={updateUserFormik.handleBlur}
            />
            {updateUserFormik.touched.mobile &&
              updateUserFormik.errors.mobile && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updateUserFormik.errors.mobile}
                </div>
              )}
          </div>
        </div>
        {/* Add fields for displaying current companies and plants */}
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Current Companies</label>
            <input
              type='text'
              className='form-control'
              value={[
                ...new Set(
                  user?.company_and_plants?.map(
                    (company) => company.company_name
                  )
                ),
              ].join(", ")}
              readOnly
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Current Plants</label>
            <input
              type='text'
              className='form-control'
              value={user?.company_and_plants
                ?.map((plant) => plant.plant_name)
                .join(", ")}
              readOnly
            />
          </div>
        </div>
        <div className='col-12'>
           <button
            type='button'
            className='btn btn-secondary mx-1'
            onClick={() => navigate("/")}
          >
          Close
          </button>
          <button
            type='submit'
            className='btn btn-primary'
          >
            Update Information
          </button>
         
        </div>
      </div>
    </form>
  );

  // JSX for rendering password update form
  const renderPasswordForm = () => (
    <form onSubmit={updatePasswordFormik.handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='emp_code'>Employee Code *</label>
            <input
              id='emp_code'
              type='emp_code'
              name='emp_code'
              className='form-control'
              readOnly
              value={updatePasswordFormik.values.emp_code}
              onChange={updatePasswordFormik.handleChange}
              onBlur={updatePasswordFormik.handleBlur}
            />
            {updatePasswordFormik.touched.emp_code &&
              updatePasswordFormik.errors.emp_code && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {updatePasswordFormik.errors.emp_code}
                </div>
              )}
          </div>
        </div>

        <div className='col-12'>
          
          <button
            type='submit'
            className='btn btn-primary'
          >
            Request Credential
          </button>
        </div>
      </div>
    </form>
  );

  // JSX for rendering user profile page
  return (
    <div className='content'>
      <div className='section-body d-flex flex-column justify-content-center align-items-center mt-3 h-100'>
        <div
          className='card  '
          style={{ width: "70%" }}
        >
          <div className='card-header'>
            <h5>User Information</h5>
          </div>
          <div className='card-body'>{user && renderUserInfoForm()}</div>
        </div>

        <div
          className='card  '
          style={{ width: "70%" }}
        >
          <div className='card-header'>
            <h5>Request New Credentials</h5>
          </div>
          <div className='card-body'>{user && renderPasswordForm()}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
