import { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { TiShoppingCart } from "react-icons/ti";
import { GoWorkflow, GoOrganization } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { usePermissions } from "../context/permissions/PermissionsProvider";
import { LuLayoutTemplate } from "react-icons/lu";
import { PiUserList } from "react-icons/pi";
import { PiFactory } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";

const SideBar = () => {
  const location = useLocation(); // Get current location
  const { authPermissions } = usePermissions(); // Get permissions from context
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const [isMastersOpen, setIsMastersOpen] = useState(false);

  const mastersPaths = [
    // "/roles",
    "/company",
    "/plant",
    "/users",
    "/productcategory",
    "/products",
  ];

  // Determine if current path is in the Masters array
  useEffect(() => {
    if (mastersPaths.includes(location.pathname)) {
      setIsMastersOpen(true);
    }
  }, []);

  const toggleMasters = () => {
    setIsMastersOpen((prev) => !prev);
  };

  return (
    <Fragment>
      <div
        id='header_top'
        className='header_top false'
      >
        <div className='container'>
          <div
            className='hleft'
            style={{ overflow: "scroll" }}
          >
            <div>
              {/* Dashboard Link */}
              <ul>
                <li className={location.pathname === "/" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                      location.pathname === "/" ? "active " : ""
                    }`}
                    to='/'
                  >
                    <RxDashboard size={20} />
                    <span className='sidebar_text'>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={location.pathname === "/job" ? "active-box" : ""}
                >
                  {authPermissions?.includes("Job Orders-List") ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        location.pathname === "/job" ? "active" : ""
                      }`}
                      to='/job'
                    >
                      <GoWorkflow size={20} />
                      <span className='sidebar_text'>Jobs</span>
                    </Link>
                  ) : null}
                </li>
                <li
                  className={
                    location.pathname === "/auditlogs" ? "active-box" : ""
                  }
                >
                  {Number(userData.role_id) !== 2 &&
                  Number(userData?.role_id) !== 15 ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        location.pathname === "/auditlogs" ? "active" : ""
                      }`}
                      to='/auditlogs'
                    >
                      <CiViewList size={20} />
                      <span className='sidebar_text'>Audit Logs</span>
                    </Link>
                  ) : null}
                </li>
                <li
                  className={
                    location.pathname === "/reports" ? "active-box" : ""
                  }
                >
                  {Number(userData.role_id) !== 2 &&
                  Number(userData?.role_id) !== 15 ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        location.pathname === "/reports" ? "active" : ""
                      }`}
                      to='/reports'
                    >
                      <TbReportAnalytics size={20} />
                      <span className='sidebar_text'>Reports</span>
                    </Link>
                  ) : null}
                </li>
              </ul>

              {/* Masters List */}
              {Number(userData?.role_id) !== 2 &&
              Number(userData?.role_id) !== 15 ? (
                <ul className={`dropdown ${isMastersOpen ? "show" : ""}`}>
                  <li>
                    <span onClick={toggleMasters}>
                      <div
                        aria-current='page'
                        className='list-a nav-link icon app_inbox d-flex flex-column'
                        style={{ color: "white" }}
                      >
                        <GrDocumentConfig size={20} />
                        <span className='sidebar_text'>Masters</span>
                        <i
                          className={`caret-left ${
                            isMastersOpen ? "active" : ""
                          }`}
                        ></i>
                      </div>
                    </span>
                    {/* Nested Masters List */}
                    <ul className={`collapse ${isMastersOpen ? "show" : ""}`}>
                      {/* {authPermissions?.includes("Roles-List") &&
                      Number(userData?.role_id) !== 1 ? (
                        <li
                          className={
                            location.pathname === "/roles" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/roles" ? "active" : ""
                            }`}
                            to='/roles'
                          >
                            <PiUserList size={20} />
                            <span className='sidebar_text'>Roles</span>
                          </Link>
                        </li>
                      ) : null} */}
                      {authPermissions?.includes("Company-List") &&
                      Number(userData?.role_id) !== 1 ? (
                        <li
                          className={
                            location.pathname === "/company" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/company" ? "active" : ""
                            }`}
                            to='/company'
                          >
                            <GoOrganization size={20} />
                            <span className='sidebar_text'>Company</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Plants-List") &&
                      Number(userData?.role_id) !== 1 ? (
                        <li
                          className={
                            location.pathname === "/plant" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/plant" ? "active" : ""
                            }`}
                            to='/plant'
                          >
                            <PiFactory size={20} />
                            <span className='sidebar_text'>Plants</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Users-List") ? (
                        <li
                          className={
                            location.pathname === "/users" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/users" ? "active" : ""
                            }`}
                            to='/users'
                          >
                            <PiUsersThree size={20} />
                            <span className='sidebar_text'>Users</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Product Groups-List") ? (
                        <li
                          className={
                            location.pathname === "/productcategory"
                              ? "active-box"
                              : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/productcategory"
                                ? "active"
                                : ""
                            }`}
                            to='/productcategory'
                          >
                            <TiShoppingCart size={20} />
                            <span className='sidebar_text'>Product Groups</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Stage Templates-List") ? (
                        <li
                          className={
                            location.pathname === "/products"
                              ? "active-box"
                              : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              location.pathname === "/products" ? "active" : ""
                            }`}
                            to='/products'
                          >
                            <LuLayoutTemplate size={20} />
                            <span className='sidebar_text'>
                              Stage Templates
                            </span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
