import { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import { useState } from "react";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import SupervisorDashboard from "./SupervisorDashboard";
import JobCard from "../Jobs/JobCard";
import DispatchGraph from "./DispatchGraph";
import CurrentJobsGraph from "./CurrentJobsGraph";
import SupervisorLoadGraph from "./SupervisorsLoadGraph";
import Nodata from "../../assets/images/noData.png";

const Dashboard = () => {
  // Retrieve user data from local storage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const navigate = useNavigate(); // Hook for programmatic navigation

  // State variables for count and recent requests
  const [count, setCount] = useState();
  const [recentRequests, setRecentRequests] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
 

 
  // Function to fetch count data
  const fetchCount = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("count");
      if (response.status === 200) {
        setCount(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
     
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  // Function to fetch recent requests data
  const fetchRecentRequests = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("request-password");
      if (response.status === 200) {
        setRecentRequests(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      } else if (error.response?.status === 404) {
        setRecentRequests([]);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    // Fetch count data only if userData is available
    if (userData && userData.userId && userData.userName) {
      fetchCount();
    }
  }, []);

  useEffect(() => {
    // Fetch data when component mounts
    fetchRecentRequests();
  }, []);

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.userId || !userData.userName) {
      navigate("/login");
    }
  }, [navigate, userData]);

  
  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : Number(userData?.role_id) === 2 ||
        Number(userData?.role_id) === 15 ? (
        <SupervisorDashboard />
      ) : (
        <Fragment>
          {/* Main content */}
          <div className='content '>
            {/* Additional sections */}
            <div className='section-body mt-3'>
              <div className='container-fluid'>
                <div className='row clearfix row-deck'>
                  {/* Section for Jobs */}
                  <div className='col-xl-12 col-lg-12 col-md-12'>
                    <div className='card'>
                      {/* <div className='card-header'>
                      <h3 className='card-title justify-content-center'>
                        Current Jobs
                      </h3>
                    </div> */}
                      <div className='card-body'>
                        <JobCard
                          data={count}
                          isSuperAdmin={true}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Section for monthly jobs */}
                  <div className='col-xl-6 col-lg-12 col-md-12'>
                    <DispatchGraph />
                  </div>
                  {/* Current Jobs */}
                  <div className='col-xl-3 col-lg-12 col-md-12'>
                    <CurrentJobsGraph />
                  </div>

                  {/* Stages Load */}
                  {/* <div className='col-xl-6 col-lg-12 col-md-12 '>
                   <StagesLoadGraph/>
                  </div> */}

                  {/* Section for recent credential requests */}

                  <div
                    className='col-xl-3 col-lg-12 col-md-12'
                    // style={{ height: "100%" }}
                  >
                    <div className='card'>
                      <div className='card-header'>
                        <h3 className='card-title'>
                          <strong>Recent Credential Requests</strong>
                        </h3>
                      </div>
                      <div className='card-body'>
                        {recentRequests?.length ? (
                          <ul>
                            {recentRequests?.map((request, index) => (
                              <li key={index}>{request.user_name}</li>
                            ))}
                          </ul>
                        ) : (
                                <div className="d-flex h-100 align-items-center">
                            <img
                              src={Nodata}
                              alt='No data found'
                              style={{
                                margin: "auto",
                                transform: 'scale(0.7)'
                               
                              }}
                                  />
                                  </div>
                         
                        )}
                      </div>
                      {recentRequests?.length > 0 ? (
                        <Link
                          to='/request'
                          className='text-right mb-2 mr-4'
                          style={{ fontSize: "14px" }}
                        >
                          Show All
                        </Link>
                      ) : null}
                    </div>
                  </div>

                  {/* Load on Supervisors */}
                  <div className='col-xl-12 col-lg-12 col-md-12 '>
                    <SupervisorLoadGraph />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Dashboard;
