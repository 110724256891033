import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ProductCategory = () => {
  const [productCategoryData, setProductCategoryData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState(null);
  const { authPermissions } = usePermissions();
  /**
   * State variable to store the filters for the data table.
   * The filters object has a `global` property that represents the global filter for the table.
   * The `global` filter has a `value` property that stores the current filter value, and a `matchMode` property that specifies the match mode for the filter (in this case, `FilterMatchMode.CONTAINS`).
   */
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

 
  useEffect(() => {
    if (productCategoryId) {
      setDeleteConfirmationVisible(true);
    }
  }, [productCategoryId]);  
  const productCategoryDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`product_category/${id}`);
      if (response.status === 200) {
        showSuccessToast("Product category deleted successfully");
        fetchData();

      }
    } catch (error) {
      showErrorToast(error.response?.data?.errors[0]?.msg);
    } finally {
      setProductCategoryId(null)
    }
  };

  /**
   * Function to fetch product category data
   * @async
   * @function fetchData
   * @returns {Promise<void>}
   */

  // Fetches the product category data from the backend and updates the state with the response.
  // This function is called when the component mounts to initialize the product category data.

  const fetchData = async () => {
    try {
      // Set isLoading to true before fetching data
      setIsLoading(true);

      // Fetch the product category data from the backend
      const response = await axiosPrivate.get("product_category");

      // If the status is successful, update the product category data
      if (response.status === 200) {
        setProductCategoryData(response.data?.data);
      }
    } catch (error) {
      // If there is an error, show an error toast
      showErrorToast(error.message);
    } finally {
      // Set isLoading to false after fetching data (whether success or error)
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  // Prepare data for rendering in DataTable
  /**
   * Maps the product category data to an array of objects with additional properties for rendering in a DataTable.
   * Each object in the array includes the original product category data, an index property, and an action property that contains buttons for editing the category.
   * The action buttons are only included if the user has the necessary permissions.
   *
   * @param {Object[]} productCategoryData - The array of product category data fetched from the backend.
   * @param {string[]} authPermissions - The array of permissions the user has.
   * @returns {Object[]} - An array of objects with product category data, index, and action properties.
   */
  const productCategoryListData = productCategoryData?.map(
    (category, index) => {
      let buttons = [];

      // Add edit button
      if (authPermissions?.includes("Product Groups-Update")) {
        buttons.push(
          <Link
            key={`editButton_${category.id}`}
            to={`/productcategory/edit/${category.id}`}
            className='btn btn-link'
            style={{ border: "none", background: "none", padding: "0" }}
            title='Edit'
          >
            <FaEdit
              color='green'
              size={13}
            />
          </Link>
        );
      }

      return { ...category, index: index + 1, action: buttons };
    }
  );


  /**
   * Function to handle global filter change
   *
   * @param {Object} event - The event object
   * @returns {void}
   */
  const onGlobalFilterChange = (event) => {
    // Get the value from the event target
    const { value } = event.target;

    // Update the filters state with the new global filter value and match mode
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };


  // Render global search input
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {authPermissions?.includes("Product Groups-Create") && (
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button
              type='button'
              className='btn btn-primary'
            >
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setProductCategoryId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='page-header'>
            <h4 className='m-1 pl-3'>Product Groups</h4>
          </div>
          <div className='card'>
            <div className='card-body p-0'>
              <div className='table-responsive'>
                <DataTable
                  value={productCategoryListData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    headerStyle={{ width: "10%" }}
                    sortable
                    bodyStyle={{ textAlign: "left" , paddingLeft: '1%'}}
                  />
                  <Column
                    field='product_category_name'
                    header='Product Category'
                    sortable
                  />
                  <Column
                    field='supervisor_name'
                    header='Line Supervisor'
                    sortable
                  />
                  <Column
                    field='plant_name'
                    header='Plant'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Action'
                    headerStyle={{ width: "3%", paddingLeft: '65px' }}
                    bodyStyle={{ textAlign: "right" }}
                    />
                    {
                      authPermissions.includes('Product Groups-Delete') && (
                        <Column
                          field='delete_handler'
                          body={deleteBodyTemplate}
                          headerStyle={{ width: "15%" }}
                          bodyStyle={{ textAlign: "left" }}
                        />
                      )
                    }
                
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
        visible={deleteConfirmationVisible}
        onHide={() => {
          setDeleteConfirmationVisible(false);
          setProductCategoryId(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='p-button-text mr-2'
              onClick={() => {
                setProductCategoryId(null);
                setDeleteConfirmationVisible(false)
              }}
            />
            <Button
              label='Delete'
              className='p-button-danger'
              onClick={() => {
                productCategoryDeleteHandler(productCategoryId);
                setDeleteConfirmationVisible(false)
              }}
            />
          </>
        }
      >
        <p>Are you sure you want to delete this product category?</p>
      </Dialog>
        </div>
      )}
    </>
  );
};

export default ProductCategory;
